<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar|uppercase }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text--primary"
      >
        {{ formattedItem.headline }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.tanomalia_checklist_ot.mejora"
      >
        <v-chip
          label
          color="green"
          dark
          small
        >
          Es una mejora
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="!readonly"
    >
      <v-btn
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'
import { GRUPO_CHECKLIST, PREGUNTA_CHECKLIST } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.tanomalia_checklist_ot.descripcion
      item.headline = item.tanomalia_checklist_ot.descripcion_ampliada
      if (item.grupo_checklist_ot.clasificacion === GRUPO_CHECKLIST.clasificacion.generales) {
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          Pregunta general "${item.pregunta_checklist_ot.descripcion}"
        `)
      } else if (item.grupo_checklist_ot.clasificacion === GRUPO_CHECKLIST.clasificacion.subsistemas) {
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          Pregunta de subsistema "${item.pregunta_checklist_ot.descripcion}"
        `)
      } else {
        item.subtitle = item.fichaTecnica.join(' - ')
      }
      item.avatar = item.grado_anomalia.alias
      if (item.tanomalia_checklist_ot.subsanado) {
        item.avatarColor = PREGUNTA_CHECKLIST.colorResultado.ok
      } else {
        item.avatarColor = PREGUNTA_CHECKLIST.colorResultado.conAnomalias
      }
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    }
  },
}
</script>
